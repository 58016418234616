import './index.module.scss';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import TreeCol from '../../components/tree-col/tree-col';
import vasIcon from '../../assets/images/vas.svg';
import dollarIcon from '../../assets/images/dollar-coin.svg';
import groupIcon from '../../assets/images/group.svg';
import shareIcon from '../../assets/images/share-money.svg';
import BannerInner from '../../components/banner-inner/banner-inner';
import ContactForm from '../../components/contact-form/contact-form';
import TextSlider from '../../components/text-slider/text-slider';
import Card from '../../components/card/card';
import banner from '../../assets/images/resouces-banner.jpg';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        bannerImage={banner}
        linktext="Learn More About Our Culture"
        link="/contact-us"
        caption={
          <div className="resources-page">
            <h2 className="large-heading color-white">
              Stay in touch with DGM through the following resources.
            </h2>
            <ul>
              <li>
                Get our monthly newsletter free of charge by clicking
                <a href="#newsletter"> HERE</a> Follow us on Facebook by
                clicking
                <a
                  href="https://www.facebook.com/DGM-Asset-Management-106660478350614"
                  target="_blank"
                >
                  {' '}
                  HERE
                </a>
              </li>
              <li>
                Follow us on Linked In by clicking{' '}
                <a
                  href="https://www.linkedin.com/company/dgm-asset-management/"
                  target="_blank"
                >
                  {' '}
                  HERE
                </a>
              </li>
              <li>
                Speak to us directly by calling
                <a href="tel:800-484-0350"> 800-484-0350</a>.
              </li>
              <li>
                Email us at{' '}
                <a href="mailto:aapostolico@dgmmmanagement.com">
                  aapostolico@dgmmmanagement.com
                </a>{' '}
                or
                <a href="mailto:info@dgmmanagement.com">
                  {' '}
                  info@dgmmanagement.com
                </a>
                .
              </li>
            </ul>
          </div>
        }
      />
      <div className="spacer"></div>
      <div className="container">
        <div className="serv-tree">
          <TreeCol title={<h2 className="large-heading ">Career Path</h2>} />
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">
                Custodian of assets
              </h2>
            }
            iconImage={vasIcon}
            content={
              <p>
                We work with one of the industry’s leading custodian of assets,
                entitling the client to all the benefits of a retail brokerage
                account (check-writing, offices to deposit checks, monthly
                statements both online and mailed) in addition to a personal
                money manager to help you manage your assets.
              </p>
            }
          />
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">Estate planning</h2>
            }
            iconImage={dollarIcon}
            content={
              <p>
                DGM can help you work with experts in the issues of wills,
                trusts, and estate planning, ensuring that your desires for a
                legacy can be met.
              </p>
            }
          />
          <TreeCol
            title={<h2 className="medium-heading color-theme">Tax work</h2>}
            iconImage={shareIcon}
            content={
              <p>
                Whether your taxes are complicated or simple, we have
                relationships with tax professionals who can help ensure that
                you are protected and aware of any strategies that can be used
                to benefit you.
              </p>
            }
          />
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">Insurance coverage</h2>
            }
            iconImage={groupIcon}
            content={
              <p>
                DGM and its trusted partners can help assess what types of
                insurance you may be deficient in and how much you may need to
                have to ensure the maximum amount of money stays in your family
                long after you are gone.
              </p>
            }
          />
        </div>
        <h3 className="text-center large-heading color-light-black">
          Looking to join a dynamic, growing form or potentially sell your
          business to one? See below.
        </h3>
        <br />
        <div className="services-container">
          <div className="d-flex justify-content-between">
            <Card
              heading={
                <h3 className="large-heading color-white">
                  Mergers & Acquisitions
                </h3>
              }
              content={
                <p>
                  If you are an industry professional looking to sell your
                  business, find a succession candidate, or merge your business
                  and stay on, please click here and provide your contact info.
                </p>
              }
              linktext="See Program Details "
              link={'/contact-us'}
            />
            <Card
              heading={
                <h3 className="large-heading color-white">
                  Join a Dynamic Team
                </h3>
              }
              content={
                <p>
                  If you are a young go-getter trying to climb the ladder in the
                  industry and would like to see what opportunities are
                  available with DGM, please click here and provide your contact
                  details.
                </p>
              }
              linktext="Learn More"
              link={'/contact-us'}
            />
          </div>
        </div>
      </div>
      <div className="spacer"></div>
      <TextSlider />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
